<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      custom-class="common__dialog common__dialog-2-column"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="$emit('closeDialog')"
    >

      <div v-if="user && dialogVisible" class="flex flex__j-sb flex__fc-mr-16">
        <el-form class="" :label-position="'top'">
          <el-form-item label="Фамилия">
            <el-input v-model="user.last_name" name="last_name" placeholder="Фамилия" clearable
                      :class="{'common__form-item-err' : errors.last_name}" @change="validate('last_name')" />
          </el-form-item>

          <el-form-item label="Имя">
            <el-input v-model="user.first_name" name="first_name" placeholder="Имя" clearable
                      :class="{'common__form-item-err' : errors.first_name}" @change="validate('first_name')" />
          </el-form-item>

          <el-form-item label="Отчество">
            <el-input v-model="user.patronymic" name="patronymic" placeholder="Отчество" clearable
                      :class="{'common__form-item-err' : errors.patronymic}" @change="validate('patronymic')" />
          </el-form-item>

          <el-form-item label="Имя пользователя">
            <el-input v-model="user.username" name="username" placeholder="username" clearable
                      :class="{'common__form-item-err' : errors.username}" @change="validate('username')" />
          </el-form-item>
        </el-form>

        <el-form class="" :label-position="'top'">
          <el-form-item label="email">
            <el-input v-model="user.email" name="email" placeholder="Email" clearable
                      :class="{'common__form-item-err' : errors.email}" @change="validate('email')" />
          </el-form-item>

          <el-form-item  label="Контактный телефон">
            <div :class="{'common__form-item-err' : errors.phone}" class="el-input el-input--suffix">
              <input v-model="user.phone" v-mask="'+7 ### ###-####'" name="phone" placeholder="000 000-00-00"
                     class="el-input__inner" @change="validate('phone')" @focus="setPhoneCode" />
            </div>
          </el-form-item>

          <el-form-item label="Дополнительная информация">
            <el-input v-model="user.description" name="description" placeholder="" clearable />
          </el-form-item>

          <el-form-item label="Пароль">
            <el-input v-model="user.password" placeholder="password" clearable
                      :class="{'common__form-item-err' : passwordErr}" />
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="el-button-32" @click="dialogVisible = false">Отмена</el-button>
        <el-button class="el-button-32" type="primary" @click="saveUser">
          {{saveButtonTitle}}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { object, string } from 'yup';
import phoneUnmask from '@/services/phoneUnmask';
import { USER_NEW_ADD_REQUEST, USER_SAVE_REQUEST } from '@/store/actions/users';

function NewUser() {
  this.last_name = '';
  this.first_name = '';
  this.patronymic = '';
  this.username = '';
  this.email = '';
  this.phone = '';
  this.description = '';
  this.password = '';
  this.role = 'user';
}

const userFormSchema = object().shape({
  last_name: string().required(),
  first_name: string().required(),
  patronymic: string().required(),
  username: string().required().min(3),
  email: string().email().required(),
  phone: string().required().min(15),
});

export default {
  name: 'user-edit',
  props: ['visible', 'userEdit'],
  data() {
    return {
      dialogVisible: false,
      user: null,
      errors: {
        last_name: '',
        first_name: '',
        patronymic: '',
        username: '',
        email: '',
        phone: '',
      },
      passwordErr: '',
    };
  },
  computed: {
    dialogTitle() {
      return this.user?.id ? 'Редактирование пользователя' : 'Новый пользователь';
    },
    saveButtonTitle() {
      return this.user?.id ? 'Сохранить' : 'Добавить';
    },
  },
  watch: {
    visible(val) {
      this.user = this.userEdit?.id ? JSON.parse(JSON.stringify(this.userEdit)) : new NewUser();
      this.dialogVisible = val;
    },
  },
  methods: {
    saveUser() {
      this.passwordErr = !this.user.password?.length && !this.user.id;

      userFormSchema
        .validate(this.user, { abortEarly: false })
        .then(() => {
          const user = JSON.parse(JSON.stringify(this.user));
          user.phone = phoneUnmask(this.user.phone);

          if (!this.passwordErr && this.user.id) {
            this.$store.dispatch(USER_SAVE_REQUEST, user).then(() => {
              console.log('save');
            });
          } else if (!this.passwordErr && !this.user.id) {
            this.$store.dispatch(USER_NEW_ADD_REQUEST, user).then(() => {
              console.log('new');
            });
          }
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
    validate(field) {
      userFormSchema
        .validateAt(field, this.user)
        .then(() => {
          this.errors[field] = '';
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    setPhoneCode() {
      this.user.phone = !this.user.phone.length ? '+7' : this.user.phone;
    },
  },
};
</script>

<style scoped>

</style>
