<template>
  <div class="common__white-block">

    <el-skeleton style="padding: 16px" :rows="10" v-if="company.loading" animated/>

    <div v-else-if="companyEdit">
      <div class="flex flex__a-ac flex__j-sb mb_24">
        <div class="flex">
          <p class="text_24 text_dark text_b mr_16">Мой гараж</p>
          <p class="text_24 text_light-gray">{{ companyEdit.name ? companyEdit.name : 'Мой гараж' }}</p>
        </div>

        <el-button :disabled="company.saveLoading" class="el-button-32" type="primary" @click="saveCompany">
          Сохранить
        </el-button>
      </div>

      <div v-if="company.needAddData" class="common__info-block common__info-block--yellow mb_24">
        <p class="text_18 text_b mb_8">Завершите настройку</p>

        <p class="text_15">
          Чтобы пользоваться всеми функциями, заполните данные вашей компании, после этого можно
          добавить сотрудников и&nbsp;настроить управление сервисом.
        </p>
      </div>

      <div class="common__form">
        <el-form class="common__form-el" :label-position="'top'">
          <el-form-item label="Наименование компании">
            <el-input v-model="companyEdit.name" name="name" placeholder="Мой гараж" clearable
                      :class="{'common__form-item-err' : errors.name}" @change="validate('name')" />
          </el-form-item>

          <el-form-item label="Юридичекское наименование">
            <el-input v-model="companyEdit.legal_name" name="legal_name" placeholder="ООО Мой гараж" clearable
                      :class="{'common__form-item-err' : errors.legal_name}" @change="validate('legal_name')" />
          </el-form-item>
        </el-form>

        <el-form class="common__form-el" :label-position="'top'">
          <el-form-item label="Адрес">
            <el-input v-model="companyEdit.address" name="address" placeholder="Адрес оранизации" clearable
                      :class="{'common__form-item-err' : errors.address}" @change="validate('address')" />
          </el-form-item>

          <el-form-item label="Email">
            <el-input v-model="companyEdit.email" name="email" type="email" :error="errors.email" placeholder="Email" clearable
                      :class="{'common__form-item-err' : errors.email}" @change="validate('email')" />
          </el-form-item>
        </el-form>

        <el-form class="common__form-phone" :label-position="'top'">
          <el-form-item  label="Контактный телефон">
            <div :class="{'common__form-item-err' : errors.phone}" class="el-input el-input--suffix">
              <input v-model="companyEdit.phone" v-mask="'+7 ### ###-####'" name="phone"
                     class="el-input__inner" @change="validate('phone')" @focus="setPhoneCode" />
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { object, string, email } from 'yup';
import phoneUnmask from '@/services/phoneUnmask';
import { COMPANY_REQUEST, COMPANY_SAVE_REQUEST } from '@/store/actions/company';
import { mapGetters } from 'vuex';

const companyFormSchema = object().shape({
  name: string().required(),
  legal_name: string().required(),
  address: string().required(),
  phone: string().required().min(15),
  email: string().email().required(),
});

export default {
  name: 'company-edit-form',
  data() {
    return {
      companyEdit: null,
      errors: {
        name: '',
        legal_name: '',
        address: '',
        phone: '',
        email: '',
      },
    };
  },
  created() {
    this.$store.dispatch(COMPANY_REQUEST).then(() => {
      this.companyEdit = JSON.parse(JSON.stringify(this.company.details));
    });
  },
  computed: {
    ...mapGetters(['company']),
  },
  methods: {
    saveCompany() {
      companyFormSchema
        .validate(this.companyEdit, { abortEarly: false })
        .then(() => {
          const company = JSON.parse(JSON.stringify(this.companyEdit));
          company.phone = phoneUnmask(this.companyEdit.phone);

          this.$store.dispatch(COMPANY_SAVE_REQUEST, company);
        })
        .catch((err) => {
          err.inner.forEach((error) => {
            this.errors = { ...this.errors, [error.path]: error.message };
          });
        });
    },
    validate(field) {
      companyFormSchema
        .validateAt(field, this.companyEdit)
        .then(() => {
          this.errors[field] = '';
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    setPhoneCode() {
      this.companyEdit.phone = !this.companyEdit.phone.length ? '+7' : this.companyEdit.phone;
    },
  },
};
</script>

<style scoped>

</style>
