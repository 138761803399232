<template>
  <div class="common__white-block">
    <el-skeleton style="padding: 16px" :rows="10" v-if="usersList.loading" animated/>

    <div v-else-if="!company.loading">
      <div class="flex flex__a-ac flex__j-sb">
        <p class="text_24 text_dark text_b">Пользователи</p>

        <el-button :disabled="company.needAddData" class="el-button-32" type="primary" @click="addUser">Добавить</el-button>
      </div>

      <el-table
        class="el-table__no-header mt_24"
        v-if="!company.needAddData"
        :data="usersList.list"
        style="width: 100%">

        <el-table-column
          min-width="180">
          <template slot-scope="scope">
            <p class="text_15 text_dark text_b">{{ getUserFullName(scope.row) }}</p>
            <p class="text_15 text_dark">{{ scope.row.phone }}</p>
          </template>
        </el-table-column>

        <el-table-column
          min-width="180">

          <template slot-scope="scope">
            <p class="text_15 text_dark">{{ scope.row.email }}</p>
          </template>
        </el-table-column>

        <el-table-column
          min-width="180">
          <template slot-scope="scope">
            <p class="text_15 text_dark">{{ scope.row.role }}</p>
          </template>
        </el-table-column>

        <el-table-column
          align="right"
          min-width="180">
          <template slot-scope="scope">
            <div class="text_15 text_blue cp" @click="userEdit(scope.row)">
              Редактировать
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <user-edit :userEdit="user" :visible="dialogVisible" @closeDialog="resetUserForm" />
  </div>
</template>

<script>
import { USERS_LIST_REQUEST } from '@/store/actions/users';
import getUserFullName from '@/services/getUserFullName';
import { mapGetters } from 'vuex';
import UserEdit from '@/views/Company/users-parts/user-edit';

export default {
  name: 'users-list',
  components: { UserEdit },
  data() {
    return {
      user: null,
      dialogVisible: false,
    };
  },
  created() {
    this.$store.dispatch(USERS_LIST_REQUEST);
  },
  computed: {
    ...mapGetters(['usersList', 'company']),
  },
  methods: {
    getUserFullName(user) {
      return getUserFullName(user).fullName;
    },
    addUser() {
      this.user = null;
      this.dialogVisible = true;
    },
    userEdit(user) {
      this.user = user;
      this.$nextTick(() => {
        this.dialogVisible = true;
      });
    },
    resetUserForm() {
      this.dialogVisible = false;
      this.user = null;
    },
  },
};
</script>

<style scoped>

</style>
