<template>
  <div>
    <p class="text_32 text_dark text_b mb_32">Компания</p>

    <company-edit-form class="mb_16" />

    <users-list />
  </div>
</template>

<script>
import CompanyEditForm from '@/views/Company/company-parts/company-edit-form';
import UsersList from '@/views/Company/users-parts/users-list';

export default {
  name: 'CompanyView',
  components: { UsersList, CompanyEditForm },
};
</script>

<style scoped>

</style>
